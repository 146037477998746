/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Link, useStaticQuery } from "gatsby";

import React from "react";
import { navigate } from "gatsby";
import Footer from "../sections/Footer/Footer";
import Header from "../sections/Header/Header";
import {
  H1,
  Helmet,
  Row,
  Col,
  Container,
  StyledSelect,
} from "gatsby-theme-landmarks-core";
import { ThemeBreadcrumbs } from "gatsby-theme-landmarks-developer";
import { Floorplan } from "../templates/Floorplan";

const InterestPoint = ({ className, color }) => (
  <span
    className={className}
    sx={{
      variant: "interestPoint",
      backgroundColor: color,
    }}
  />
);

export default (props) => {
  const { pageContext, metaTitle, metaDescription } = props;
  const {
    publicThemeOptions,
    uniqueFloors,
    apartamente,
    etaj,
    breadcrumb: { crumbs },
  } = pageContext;
  const { theme } = useThemeUI();
  const selectedFloorIndex = uniqueFloors.findIndex((x) => {
    const [_, nr] = x.label.split(" ");
    return nr === etaj.nr;
  });

  const navFloor = [
    "PARTER",
    "MEZANIN",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    "RETRAS",
    "S3",
    "S2",
    "S1",
  ];
  let prevFloor = navFloor[etaj.nr];
  let nextFloor = navFloor[prevFloor + 3];

  switch (etaj.nr) {
    case "S1":
      nextFloor = "PARTER";
      prevFloor = "S2";
      break;
    case "S2":
      nextFloor = "S1";
      prevFloor = "S3";
      break;
    case "S3":
      nextFloor = "S2";
      prevFloor = "";
      break;
    case "MEZANIN":
      nextFloor = "1";
      prevFloor = "PARTER";
      break;
    case "PARTER":
      nextFloor = "MEZANIN";
      prevFloor = "S1";
      break;
    case "RETRAS":
      nextFloor = "";
      prevFloor = 14;
      break;
    case "1":
      nextFloor = 2;
      break;
  }

  const countDisponibile = apartamente.filter((x) => x.status === "disponibil")
    .length;
  const data = useStaticQuery(graphql`
    {
      plan: file(ext: { eq: ".svg" }, name: { eq: "plan" }) {
        publicURL
      }
      planparter: file(ext: { eq: ".svg" }, name: { eq: "plan-parter" }) {
        publicURL
      }
      plan114: file(ext: { eq: ".svg" }, name: { eq: "plan-1-14" }) {
        publicURL
      }
      planmezanin: file(ext: { eq: ".svg" }, name: { eq: "plan-mezanin" }) {
        publicURL
      }
      planretras: file(ext: { eq: ".svg" }, name: { eq: "plan-retras" }) {
        publicURL
      }
    }
  `);
  return (
    <>
      <Helmet>
        <title>{`Etaj ${etaj.nr} | ${publicThemeOptions.name}`}</title>
        {metaTitle && (
          <meta
            name="title"
            content={`Etaj ${etaj.nr} | ${publicThemeOptions.name}`}
          />
        )}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Helmet>

      <Header logoSrc={props.logoSrc} logoAlt={publicThemeOptions.name} />

      <Container
        sx={{
          px: [3, 4],
        }}
      >
        <Row
          sx={{
            pt: ["108px", null, 4],
          }}
        >
          {/* <Col
            sx={{
              alignSelf: 'flex-start',
              position: ['initial', null, 'sticky'],
              top: '170px',
              overflow: 'hidden'
            }}
          >
            <ResponsiveNavigation
              selectedIndex={selectedFloorIndex}
              links={uniqueFloors}
              sx={{
                pr: 4
              }}
            />
          </Col> */}

          <Col
            sx={{
              flex: ["auto", "auto", 3],
            }}
          >
            <Row
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                pt: [0, "100px"],
              }}
            >
              {/* <Col
                width={[12 / 12, 12 / 12, 12 / 12]}
                sx={{
                  mb: [5, 0],
                }}
              >
                <div
                  sx={{
                    a: {
                      display: "inline-block",
                      p: 2,
                    },
                  }}
                ></div>

                <div
                  sx={{
                    backgroundColor: "primary",
                    py: "4",
                    pl: "4",
                    color: "grey",
                  }}
                >
                  <Row>
                    <Col>
                      <StyledSelect
                        value={
                          selectedFloorIndex &&
                          uniqueFloors[selectedFloorIndex].to
                        }
                        onChange={navigate}
                        sx={{
                          mb: 0,
                          minHeight: 0,
                        }}
                      >
                        {uniqueFloors.map((link) => (
                          <option key={link.to} value={link.to}>
                            {link.label}
                          </option>
                        ))}
                      </StyledSelect>
                    </Col>
                  </Row>
                  <p sx={{ opacity: ".8", color: "white" }}>
                    {countDisponibile === 1
                      ? `1 apartament disponibil`
                      : `${countDisponibile} apartamente disponibile`}
                  </p>
                </div>
              </Col> */}

              <Col width={[12 / 12, 12 / 12, 8 / 12]}>
                {etaj.nr.includes("RETRAS") ?
                  <Floorplan
                    apartamente={apartamente}
                    background={data.planretras.publicURL}
                  />
                  :
                  (etaj.nr.includes("PARTER") ?
                  <Floorplan
                    apartamente={apartamente}
                    background={data.planparter.publicURL}
                  /> :
                  ( etaj.nr.includes("MEZANIN") ?
                  <Floorplan
                  apartamente={apartamente}
                  background={data.planmezanin.publicURL}
                /> :
                ( etaj.nr.includes("S") ?
                <Floorplan
                apartamente={apartamente}
                />
                :
                  <Floorplan
                  apartamente={apartamente}
                  background={data.plan.publicURL}
                />
                )))}
              </Col>
              <Col width={[12 / 12, 12 / 12, 3 / 12]} sx={{mt: 3}}>
              <div>
                  <Row>
                    <Col width={[2 / 12, 4 / 12, 2 / 12]} sx={{  mt: 2 }}>
                      <Link
                        to={
                          etaj.nr == "S3"
                            ? ""
                            : "/apartamente-noi/etaj-" + prevFloor
                        }
                        sx={{
                          color: "black",
                          textDecoration: "none",
                          fontSize: 6,
                        }}
                      >
                        {etaj.nr == "S3" ? (
                          ""
                        ) : (
                            <div
                              sx={{
                                width: 0,
                                height: 0,
                                borderTop: "20px solid transparent",
                                borderRight: " 40px solid #555",
                                borderBottom: "20px solid transparent",
                                marginLeft:"auto"
                              }}
                            ></div>
                          )}
                      </Link>
                    </Col>
                    <Col width= {[8/12, 4/12, 8/12]}>
                      <H1
                        sx={{
                          variant: "text.golden",
                          textAlign: "center",
                          mb: 2
                        }}
                      >
                        {/* {etaj.nr !== "PARTER"
                          ? etaj.nr !== "MEZANIN"
                            ? etaj.nr !== "RETRAS"
                              ? `Etaj ${etaj.nr}`
                              : "RETRAS"
                            : "MEZANIN"
                          : etaj.nr} */}

                        {etaj.nr > 0 ? `Etaj ${etaj.nr}` : (etaj.nr.includes("S") ? (etaj.nr == "RETRAS" ? etaj.nr : `Subsol ${etaj.nr}`) : etaj.nr)}
                      </H1>
                    </Col>
                    <Col sx={{ mt: 2 }} width={[2 / 12, 4 / 12, 2 / 12]}>
                      <Link
                        to={
                          etaj.nr == "RETRAS"
                            ? ""
                            : "/apartamente-noi/etaj-" + nextFloor
                        }
                        sx={{
                          color: "black",
                          textDecoration: "none",
                          fontSize: 6,
                        }}
                      >
                        {etaj.nr == "RETRAS" ? (
                          ""
                        ) : (
                            <div
                              sx={{
                                width: 0,
                                height: 0,
                                borderTop: "20px solid transparent",
                                borderLeft: " 40px solid #555",
                                borderBottom: "20px solid transparent",
                                marginRight: "auto"
                              }}
                            ></div>
                          )}
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: ["row", "row", "column"],
                    justifyContent: ["center"],
                    my: [4],
                  }}
                >
                  <div sx={{ display: "flex", alignItems: "center", mr: 4 }}>
                    <InterestPoint color={theme.floorplan.colors.disponibil} />
                    Disponibil
                  </div>
                  <div sx={{ display: "flex", alignItems: "center", mr: 4 }}>
                    <InterestPoint color={theme.floorplan.colors.rezervat} />
                    Rezervat
                  </div>
                  <div sx={{ display: "flex", alignItems: "center", mr: 4 }}>
                    <InterestPoint color={theme.floorplan.colors.vandut} />
                    Vandut
                  </div>
                  <div sx={{ display: "flex", alignItems: "center", mr: 4 }}>
                    <InterestPoint color={theme.floorplan.colors.inactiv} />
                    Indisponibil
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
