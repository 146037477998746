import React from 'react'

import Apartament from '../../templates/apartament'
import logo from '../../images/site/Scala-Center-horizontal.png'


export default props => {

  return <Apartament
    {...props}
    logoSrc={logo}
    logoAlt="Scala Frunzisului"
    metaTitle="Scala Frunzisului | Ansamblu rezidential in centrul orasului Cluj-Napoca"
    metaDescription="Apartamente noi de vanzare direct de la constructor, cu 1-3 camere si terasa. 🏡 Ansamblu rezidential in Cluj-Napoca. Vezi Apartamente!"
    />
}