import isNumber from 'lodash/isNumber'
import * as R from 'ramda'

const firstItemIsNumber = R.pipe(
  R.head,
  isNumber
)

const firstItemIsArray = R.pipe(
  R.head,
  Array.isArray
)
const getValueFromArrayAtCol = col => R.map(
  R.pipe(
    R.defaultTo([]), // some breakpoints are null instead of an array
    R.nth(col)
  )
)
const getValueAtCol = col => R.pipe(
  R.nth(col),
  R.append(R.__, [])
)

export const getCol = R.curry((col, data) => R.ifElse(
  firstItemIsArray,
  getValueFromArrayAtCol(col),
  getValueAtCol(col)
)(data))

export const maybeArrayWrap = R.ifElse(
  firstItemIsNumber,
  (data) => [data],
  R.identity
)

export const noop = () => {}

export const toDoubleDigit = nr => `0${nr}`.slice(-2)