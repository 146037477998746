/** @jsx jsx */
import { jsx } from 'theme-ui'

import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Button, Spinner } from 'theme-ui'
import { Formik } from 'formik'

import { Error } from 'gatsby-theme-landmarks-core'
import { InputField, CheckboxField, SelectField } from '../components/Fields/Fields'

export const MoreDetails = props => {
  const { apartament, siteTitle } = props
  const { uuid } = apartament
  const [globalError, setGlobalError] = useState('')
  const validate = values => {
    const errors = {}

    if (!values.nume) {
      errors.nume = 'Va rugam sa va introduceti numele'
    }
    if (!values.telefon) {
      errors.telefon = 'Va rugam sa introduceti numarul de telefon'
    }
    // else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(values.telefon)) {
    //   errors.telefon = `Numarul de telefon este invalid`
    // }
    if (!values.email) {
      errors.email = 'Va rugam sa introduceti adresa de email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Adresa de email este invalida'
    }
    if (!values.mesaj) {
      errors.mesaj = 'Ati uitat sa introduceti mesajul'
    }
    if (!values.acordPolitica) {
      errors.acordPolitica = true
    }
    return errors
  }

  return (
    <Formik
      initialValues={{
        nume: '',
        telefon: '',
        email: '',
        mesaj: `Sunt interesat de apartamentul ${uuid} de la etajul ${apartament.floor}`,
        source: null,
        acordPolitica: false,
        acordInformari: false,
        day: new Date().getDate().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getFullYear().toString()
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        fetch(`/.netlify/functions/submit_lead_update`, {
          method: 'POST',
          body: JSON.stringify({
            data: values
          })
        })
          .then(res => {
            if (res.status === 200) {
              navigate('/thank-you')
            } else {
              setGlobalError('Something went wrong')
              setSubmitting(false)
            }
            console.log(res)
          })
          .catch(err => {
            console.log(err)
            setGlobalError(err)
            setSubmitting(false)
          })
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
          <InputField name="nume" label="Nume" />
          <InputField type="tel" name="telefon" label="Telefon" />
          <InputField type="email" name="email" label="Email" />
          <SelectField name="sursa" label="De unde ati aflat de noi?">
            <option value="">-</option>
            <option value="facebook">Facebook</option>
            <option value="google">Google</option>
            <option value="prieten">Prieten</option>
            <option value="banner">Banner</option>
          </SelectField>
          <InputField as="textarea" name="mesaj" label="Mesaj" />
          <CheckboxField
            name="acordPolitica"
            label={
              <>
                am luat la cunostinta{' '}
                <Link to="politica-confidentialitate-gdpr">
                  Politica de Confidenţialitate şi Protecţia Datelor cu Caracter
                  Personal
                </Link>
              </>
            }
          />
          <CheckboxField
            name="acordInformari"
            label={siteTitle}
          />
          {globalError && <Error sx={{ my: 3 }}>{globalError}</Error>}
          <Button
            sx={{
              variant: props.isSubmitting && 'buttons.disabled',
              my: 4
            }}
            disabled={props.isSubmitting}
            type="submit"
          >
            {props.isSubmitting ? (
              <>
                <Spinner sx={{ variant: 'styles.spinner' }} />
                Se trimite
              </>
            ) : (
              'Trimite'
            )}
          </Button>
        </form>
      )}
    </Formik>
  )
}
