import React from 'react'

import Modele from '../../templates/modele'
import logo from '../../images/site/Scala-Center-horizontal.png'

export default props => {
  return <Modele
    {...props}
    logoSrc={logo}
    logoAlt="Scala Frunzisului"
    metaTitle="Scala Frunzisului"
    metaDescription="Apartamente noi de vanzare direct de la constructor, cu 1-3 camere si terasa. 🏡 Ansamblu rezidential in Cluj-Napoca. Vezi Apartamente!"

    />
}