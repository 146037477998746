/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

import React from 'react'
import { navigate } from 'gatsby'
import { APARTMENT_STATUS } from '../templates/config'
import Footer from '../sections/Footer/Footer'
import Header from '../sections/Header/Header'

import {
  H1,
  Helmet,
  A,
  Container,
  Card,
  StyledSelect
} from 'gatsby-theme-landmarks-core'

import {
  toDoubleDigit,
  CardOverview,
} from 'gatsby-theme-landmarks-developer'

const titleText = {
  1: 'Apartamente cu 1 cameră',
  0: 'Toate apartamentele',
  2: 'Apartamente cu 2 camere',
  3: 'Apartamente cu 3 camere',
}

const CardSelector = ({ selectedRoomNr, uniqueRoomNumbers, ...props }) => (
  <Card
    {...props}
    sx={{
      mt: [null, null, 6],
      maxWidth: [null, null, '480px'],
      '> a': {
        display: 'block'
      }
    }}
  >
    <H1 sx={{
    mb: 3,
    fontSize: '32px',
      color:'#6C6C6C'
    }}>{titleText[selectedRoomNr]}</H1>
    <A to={`/apartamente-noi`}>Toate</A>
  
    {uniqueRoomNumbers.map(rooms => ( 
      <div> {rooms !== 0 && <A to={`/apartamente-noi/${rooms}-camere`}>{rooms} {rooms == 1 ? 'camera' : 'camere' }</A>} </div>
    ))} 
  
  </Card>
)

export default props => {
  const { pageContext, logoSrc, logoAlt, metaTitle, metaDescription } = props
  const { modele, uniqueRoomNumbers, selectedRoomNr = 0 } = pageContext

  return (
    <>
      <Helmet>
        <title>{`${titleText[selectedRoomNr]} | ${logoAlt}`}</title>
        {metaTitle && (
          <meta
            name="title"
            content={`${titleText[selectedRoomNr]} | ${logoAlt}`}
          />
        )}
        {metaDescription && (
          <meta
            name="description"
            content={props.metaDescription}
          />
        )}
      </Helmet>

      <Header logoSrc={logoSrc} logoAlt={logoAlt} />
      <Container
        sx={{
          pr: [3, 4],
          pl: [3, 7],
          pt: '200px'
        }}
      >
        <Grid gap="5" columns={[1, 1, 2]}>
          <div>
            <CardSelector
              selectedRoomNr={selectedRoomNr}
              uniqueRoomNumbers={uniqueRoomNumbers}
            />
          </div>
          {modele.map((model, i) => {
            const isFirst = i === 0
            const isEven = i % 2 === 0
            const number = toDoubleDigit(model.uuid)
            const countDisponibilitate = model.apartament.filter(
              x => x.status === APARTMENT_STATUS.DISPONIBIL
            ).length
            const mesajeDisponibilitate = count => {
              return count === 0
                ? `Indisponibil`
                : count === 1
                ? `1 apartament disponibil`
                : `${count} apartamente disponibile`
            }

            return (
            <div>  
              { model.rooms > 0 
                &&
              <div
                sx={{
                  mt: () =>
                    isFirst
                      ? null
                      : isEven
                      ? [null, null, '300px']
                      : [null, null, '-200px']
                }}
              >
          
                <CardOverview
                  title={number}
                  subtitle="model"
                  fluid={model.image.fluid}
                  size={model.size}
                  rooms={model.rooms}
                  terrace={model.terrace}
                >
                  <div sx={{ mt: [4, 3] }}>
                    <StyledSelect
                      label={mesajeDisponibilitate(countDisponibilitate)}
                      onChange={apNr => {
                        const cameraText = model.rooms > 1 ? 'camere' : 'camera'
                        const selectedAp = model.apartament.find(
                          x => x.uuid === parseInt(apNr, 10)
                        )
                        const link = `apartamente-noi/apartament-${model.rooms}-${cameraText}-${selectedAp.uuid}`
                        navigate(link)
                      }}
                    >
                      <option value="">Alege</option>
                      {model.apartament
                        .sort(disponibilFirst)
                        .map(ap => (
                          <option
                            value={ap.uuid}
                          >{`${ap.status} - Ap. ${ap.uuid} - Etaj ${ap.floor}`}</option>
                        ))}
                    </StyledSelect>
                  </div>
                </CardOverview>
                

                {/* <Row>
                  <Col sx={{ flex: [0, 0, 3] }}>
                    <H3
                      sx={{
                        mb: 3,
                        textAlign: 'right',
                        zIndex: 3,
                        position: 'relative'
                      }}
                    >
                      <small
                        sx={{
                          fontSize: 2,
                          fontFamily: 'body',
                          verticalAlign: 'top'
                        }}
                      >
                        model
                      </small>
                      <span sx={{ fontSize: '100px', color: 'secondary' }}>
                        {number}
                      </span>
                    </H3>
                    <Plx
                      parallaxData={[
                        {
                          start: 'self',
                          duration: 1000,
                          properties: [
                            {
                              startValue: 0,
                              endValue: -100,
                              property: 'translateY'
                            }
                          ]
                        }
                      ]}
                    >
                      <Img fluid={model.image.fluid} />
                      <div sx={{ mt: [4, 3] }}>
                        <StyledSelect
                          label={mesajeDisponibilitate(countDisponibilitate)}
                          onChange={apNr => {
                            const cameraText =
                              model.rooms > 1 ? 'camere' : 'camera'
                            const selectedAp = model.apartamente.find(
                              x => x.number === parseInt(apNr, 10)
                            )
                            const link = `apartamente-noi/apartament-${model.rooms}-${cameraText}-${selectedAp.number}`
                            navigate(link)
                          }}
                        >
                          <option value="">Alege</option>
                          {model.apartamente.map(ap => (
                            <option value={ap.number}>{`${
                              ap.sold ? 'Indisponibil - ' : ''
                            }Ap. ${ap.number} - Etaj ${ap.floor}`}</option>
                          ))}
                        </StyledSelect>
                      </div>
                    </Plx>
                  </Col>
                  <Col sx={{ pl: 4, pt: [null, null, '174px'] }}>
                    <p>
                      <CustomStrong>
                        {model.size}
                        <Small>mp</Small>
                      </CustomStrong>
                      <span>suprafata utila</span>
                    </p>
                    <p>
                      <CustomStrong>{model.rooms}</CustomStrong>
                      <span>camere</span>
                    </p>
                    <p>
                      <CustomStrong>
                        {model.terrace}
                        <Small>mp</Small>
                      </CustomStrong>
                      <span>terasa</span>
                    </p>
                  </Col>
                </Row> */}
              </div>
          }
            </div>
            )
          })}
          {modele.length > 4 && (
            <div>
              <CardSelector
                sx={{ mb: [3, null, 6] }}
                selectedRoomNr={selectedRoomNr}
                uniqueRoomNumbers={uniqueRoomNumbers}
              />
            </div>
          )}
        </Grid>
      </Container>
      <Footer />
    </>
  )
}

const disponibilFirst = a => a.status === APARTMENT_STATUS.DISPONIBIL && -1