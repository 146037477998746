/** @jsx jsx */
import { jsx } from "theme-ui"

const initial = 'initial'
const fullWidth = 'auto'

export const Row = (props) => <div sx={{
  display: ['block', 'block', 'flex'],
  flexDirection: [initial, initial, 'row'],
  flexWrap: [initial, initial, 'wrap'],
}} {...props} />

export const Col = (props) => <div sx={{
  flexBasis: '100%',
  flex: [fullWidth, fullWidth, 1],
  position: 'relative',
}} {...props} />