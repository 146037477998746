import React from 'react'

import Etaj from '../../templates/etaj'
import logo from '../../images/site/Scala-Center-horizontal.png'

export default props => {
  return <Etaj
    {...props}
    logoSrc={logo}
    logoAlt="Scala Frunzisului"
    metaTitle="Scala Frunzisului"
    metaDescription="Apartamente noi de vanzare direct de la constructor, cu 1-3 camere si terasa. 🏡 Ansamblu rezidential in Cluj-Napoca. Vezi Apartamente!"
    />
}