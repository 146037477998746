// /** @jsx jsx */
// import { jsx, Box } from "theme-ui";

// import React from "react";
// import { Helmet } from "react-helmet";
// import { navigate } from "gatsby";
// import { APARTMENT_STATUS } from "../templates/config";
// import etaj from "gatsby-theme-landmarks-developer/src/templates/etaj";

// const Apartament = (props) => {
//   const [planX, planY] = props.floorplanposition.split("/");
//   const [pinX, pinY] = props.model.pinposition.split("/");
//   const disponibil = props.status === APARTMENT_STATUS.DISPONIBIL;
//   const inactiv = props.status === APARTMENT_STATUS.INACTIV;
//   // const isSelected = props.uuid === selectedApNumber;
//   const thisApartment = props.isSelected;

//   return (
//     <Box
//       as="g"
//       transform={`translate(${planX}, ${planY})`}
//       className={props.className}
//       onClick={() =>
//         !inactiv && !props.floor.includes("S") && navigate(props.link)
//       }
//       sx={{
//         variant: "floorplan.apartment", // get the value from the theme
//         cursor: "pointer",
//         "& #diamond": {
//           fill: (theme) => theme.floorplan.colors[props.status],
//         },
//         "#floor": {
//           fill: (theme) => thisApartment && "secondary",
//         },
//         "&:hover": {
//           "#diamond": {},
//           "#floor": {
//             // fill: theme => disponibil && 'secondary'
//           },
//           "#terrace": {
//             // fill: theme => disponibil && 'secondary'
//           },
//           text: {
//             fontSize: !inactiv && "18px",
//           },
//         },
//         ...(inactiv && {
//           cursor: "initial",
//           opacity: 0.4,
//         }),
//       }}
//     >
//       <Box as="g" dangerouslySetInnerHTML={{ __html: props.model.floorplan }} />
//     </Box>
//   );
// };

// export const FloorplanMini = (props) => {
//   const { apartamente, selectedApNumber } = props;

//   const {
//     uuid,
//     price,
//     floor,
//     status,
//     activ,
//     model,
//     floorplanposition,
//   } = apartamente;
//   const isSelected = props.uuid === selectedApNumber;

//   return (
//     <div
//       sx={{
//         overflow: "hidden",
//         mx: ["-16px", "-16px", 0],
//       }}
//     >
//       <Helmet>
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, maximum-scale=3"
//         />
//       </Helmet>
//       <svg
//         viewBox="25 100 670 670"
//         version="1.1"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         {apartamente.map((ap) => (
//           <Apartament key={`ap-${ap.floor}-${ap.uuid}-${ap.selectedApNumber}`} {...ap}>
//             {ap.model.floorplan}
//           </Apartament>
//         ))}
//       </svg>
//       <div>
//       {selectedApNumber}
//       </div>
//     </div>
//   );
// };

/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { navigate } from "gatsby";
import { APARTMENT_STATUS } from "../templates/config";
import etaj from "gatsby-theme-landmarks-developer/src/templates/etaj";

export const FloorplanMini = (props) => {
  const { apartamente, selectedApNumber } = props;


  return (
    <svg viewBox="25 100 670 670" version="1.1" xmlns="http://www.w3.org/2000/svg">
      {apartamente.map((ap) => {
        const [planX, planY] = ap.floorplanposition.split("/");
        const [pinX, pinY] = ap.model.pinposition.split("/");
        const isSelected = ap.uuid === selectedApNumber;
        const disponibil = ap.status === APARTMENT_STATUS.DISPONIBIL;
        const inactiv = ap.status === APARTMENT_STATUS.INACTIV;

        return (
          <Box
            as="g"
            transform={`translate(${planX}, ${planY})`}
            className={ap.className}
            onClick={() => !inactiv && navigate(ap.link)}
            sx={{
              variant: "floorplan.apartment",
              cursor: "pointer",
              "& #diamond": {
                fill: (theme) => theme.floorplan.colors[ap.status],
              },
              "#floor": {
                fill: (theme) => isSelected && "secondary",
              },
              "#terrace": {
                fill: (theme) => isSelected && "secondary",
              },
              text: {
                fontSize: !inactiv && "18px",
              },
              ...(inactiv && {
                cursor: "initial",
                opacity: 0.4,
              }),
            }}
          >
            <Box
              as="g"
              dangerouslySetInnerHTML={{ __html: ap.model.floorplan }}
            />
          </Box>
        );
      })}
    </svg>
  );
};
