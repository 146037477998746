/** @jsx jsx */
import { jsx, Label, Input, Checkbox, Textarea, Select } from 'theme-ui'

import React from 'react'
import { useField } from 'formik'

export const InputField = ({ label, as = 'input', ...props }) => {
  const [field, meta] = useField(props)
  const fieldId = props.id || props.name
  const isError = meta.touched && meta.error
  const toThemeUiComponents = {
    textarea: Textarea
  }
  const finalAs = toThemeUiComponents[as] || as

  return (
    <div sx={{ mb: 2 }}>
      <Label
        htmlFor={fieldId}
        sx={{
          variant: isError && 'forms.label.error'
        }}
      >
        {label}
      </Label>
      <Input
        id={fieldId}
        as={finalAs}
        {...field}
        {...props}
        sx={{
          variant: isError && 'forms.input.error'
        }}
      />
      <FieldError>{meta.touched && meta.error && meta.error}</FieldError>
    </div>
  )
}

export const CheckboxField = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  const isError = meta.touched && meta.error

  return (
    <div
      sx={{
        display: 'flex',
        userSelect: 'none'
      }}
    >
      <Label
        sx={{
          variant: isError && 'forms.checkbox.error'
        }}
      >
        <Checkbox {...field} {...props} />
        {label}
      </Label>
    </div>
  )
}

export const SelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const fieldId = props.id || props.name
  const isError = meta.touched && meta.error

  return (
    <div sx={{ mb: 2 }}>
      <Label
        htmlFor={fieldId}
        sx={{
          variant: isError && 'forms.label.error'
        }}
      >
        {label}
      </Label>
      <Select
        id={fieldId}
        sx={{
          variant: isError && 'forms.input.error'
        }}
        {...field}
        {...props}
      />
      <FieldError>{meta.touched && meta.error && meta.error}</FieldError>
    </div>
  )
}

export const FieldError = props => (
  <strong
    sx={{
      variant: 'forms.fieldError',
      height: 3
    }}
    {...props}
  />
)
