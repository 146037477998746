/** @jsx jsx */
import { jsx } from 'theme-ui'

import { H3 } from '../../tags/tags'

export const FunkyTitle = ({title, subtitle, ...props}) => (
  <H3
    sx={{
      mb: 0,
      textAlign: 'right',
      zIndex: 3,
      position: 'relative'
    }}
  >
    <small
      sx={{
        fontSize: 2,
        fontFamily: 'body',
        verticalAlign: 'top'
      }}
    >
      {subtitle}
    </small>
    <span sx={{ variant: 'funkyTitle.large' }} {...props}>{title}</span>
  </H3>
)
