import React, { createContext, useReducer } from 'react'

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()
export const globalActions = {
  TOGGLE_SHOW_GDPR: 'TOGGLE_SHOW_GDPR'
}

const TRACKING_FLAG = 'allowTracking'
const allowTracking = typeof sessionStorage === 'undefined'
  ? null
  : JSON.parse(sessionStorage.getItem(TRACKING_FLAG))
const notAnsweredAlready = allowTracking === null
const initialState = {
  showGDPR: notAnsweredAlready
}
const reducer = (state, action) => {
  switch (action.type) {
    case globalActions.TOGGLE_SHOW_GDPR:
      return {
        ...state,
        showGDPR: !state.showGDPR,
      }
    default:
      throw new Error(`Unknown action ${action.type}`)
  }
}

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}