/** @jsx jsx */
import { jsx } from 'theme-ui'

import React from 'react'
import { Link } from 'gatsby'
import { Heading, Text, Input, Label, Select } from 'theme-ui'

export const H1 = ({ sx, ...rest }) => (
  <Heading
    as="h1"
    sx={{
      fontSize: 6,
      mb: 5
    }}
    {...rest}
  />
)

export const H2 = ({ sx, ...rest }) => (
  <Heading
    as="h2"
    sx={{
      fontSize: 6,
      mt: 3,
      mb: 4,
    }}
    {...rest}
  />
)

export const H3 = ({ sx, ...rest }) => (
  <Heading
    as="h3"
    sx={{
      fontSize: 5,
      mb: 4
    }}
    {...rest}
  />
)

export const H4 = ({ sx, ...rest }) => (
  <Heading
    as="h4"
    sx={{
      fontSize: 4
    }}
    {...rest}
  />
)

export const Strong = ({ sx, ...rest }) => (
  <Text
    as="strong"
    variant="golden"
    sx={{
      fontSize: 5
    }}
    {...rest}
  />
)

export const Small = ({ sx, ...rest }) => (
  <Text
    as="small"
    sx={{
      fontSize: 0,
      display: 'inline'
    }}
    {...rest}
  />
)

export const StyledLink = (props) => (
  <Link
    {...props}
    activeClassName="active"
    sx={{
      color: 'inherit',
      textDecoration: 'none',
      py: 3,
      ...props.selected && {
        color: 'secondary',
        fontSize: 5
      },
      '&.active': {
        color: 'secondary',
        fontSize: 5
      }
    }}
  />
)

// export const StyledLabel = props => (
//   <Label
//     className={props.className}
//     htmlFor={props.name}
//     sx={{
//       color: 'mediumGray'
//     }}
//   >
//     {props.label}
//   </Label>
// )

const inputStyle = {
  fontSize: 3,
  fontFamily: 'heading',
  borderRadius: 0,
  outline: 'none',
  borderLeft: 'none',
  borderTop: 'none',
  borderRight: 'none',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  pl: 0
}

export const StyledField = props => {
  const { name, value, onChange, as = 'input', required, label, error } = props

  return (
    <div
      sx={{
        minHeight: '80px',
        mb: 4
      }}
    >
      <Label htmlFor={name}>
        {label}
        {required && <small>*</small>}
      </Label>
      <Input
        as={as}
        id={name}
        value={value}
        onChange={onChange}
        sx={inputStyle}
      />
      {error && <FieldError>{error}</FieldError>}
    </div>
  )
}

export const FieldError = props => (
  <strong
    sx={{
      color: 'error',
      fontSize: 1
    }}
    {...props}
  />
)

export const StyledSelect = ({onChange, ...rest}) => (
  <div
    sx={{
      minHeight: '80px',
      mb: 4
    }}
    {...rest}
  >
    <Label htmlFor={rest.name}>{rest.label}</Label>
    <Select
      id={rest.name}
      value={rest.value}
      onChange={e => onChange(e.target.value)}
      sx={inputStyle}
    >
      {rest.children}
    </Select>
    {rest.errors && <strong>{rest.errors}</strong>}
  </div>
)

export const A = props => <Link {...props} sx={{ variant: 'links.default' }} />