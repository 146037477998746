/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

import React from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { APARTMENT_STATUS } from '../templates/config'
import etaj from 'gatsby-theme-landmarks-developer/src/templates/etaj'

const Apartament = props => {

  const [planX, planY] = props.floorplanposition.split('/')
  const [pinX, pinY] = props.model.pinposition.split('/')
  const disponibil = props.status === APARTMENT_STATUS.DISPONIBIL
  const inactiv = props.status === APARTMENT_STATUS.INACTIV
  
  
  return (
    <Box
      as="g"
      transform={`translate(${planX}, ${planY})`}
      className={props.className}
      onClick={() => !inactiv && !props.floor.includes("S1") && !props.floor.includes("S2") && !props.floor.includes("S3") && navigate(props.link)}
      sx={{
        variant: 'floorplan.apartment', // get the value from the theme
        cursor: 'pointer',
        '& #diamond': {
          fill: theme => theme.floorplan.colors[props.status]
        },
        '&:hover': {
          '#diamond': {
            strokeWidth: !inactiv && '7px',
            stroke: theme => theme.floorplan.colors[props.status],
            fillOpacity:'1'
          },
          '#floor': {
            fill: theme => disponibil && 'secondary'
          },
          '#terrace': {
            fill: theme => disponibil && 'secondary'
          },
          text: {
            fontSize: !inactiv && '18px'
          }
        },
        ...(inactiv && {
          cursor: 'initial',
          opacity: 0.4
        })
      }}
    >
      <Box as="g" dangerouslySetInnerHTML={{ __html: props.children }} />
      <text
        transform={`translate(${pinX}, ${pinY})`}
        sx={{
          fontSize: '15px',
          fontWeight:'bold',
          textAnchor: 'middle',
          fill: 'white'
        }}
      >
        {props.uuid}
      </text>
    </Box>
  )
}

export const Floorplan = props => {
  const { apartamente } = props
  const {
    uuid,
    price,
    floor,
    status,
    activ,
    model,
    floorplanposition,
  } = apartamente;
  return (
    <div
      sx={{
        overflow: 'hidden',
        mx: ['-16px', '-16px', 0]
      }}
    >
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=3"
        />
      </Helmet>
      <svg
        viewBox="-108 -20 975 970"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          display: 'block',
          width: ['100vw', '100vw', '100%'],
          // width: ['auto', 'auto', '100%'],
          // height: ['100vh', '90vh', 'auto'],
          backgroundImage: `url(${props.background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          fillOpacity: '0.85'
        }}
      >
        {apartamente.map(ap => (
          <Apartament key={`ap-${ap.floor}-${ap.uuid}`} {...ap}>
            {ap.model.floorplan}
          </Apartament>
        ))}
      </svg>
    </div>
  )
}
