/** @jsx jsx */
import { jsx, Badge } from "theme-ui";

import React from "react";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FloorplanMini } from "../templates/FloorplanMini";
import { APARTMENT_STATUS } from "../templates/config";
import Collapse from "../components/Collapse/CollapseOpen";
import {
  Button,
  Helmet,
  Row,
  Col,
  H1,
  H3,
  H4,
  Card,
  Grid,
  Small,
  Strong,
  ResponsiveNavigation,
  Container,
  Text,
  StyledSelect,
} from "gatsby-theme-landmarks-core";
import { navigate } from "gatsby";
import {
  MoreDetails,
  ThemeBreadcrumbs,
} from "gatsby-theme-landmarks-developer";
import Footer from "../sections/Footer/Footer";
import Header from "../sections/Header/Header";
import etaj from "gatsby-theme-landmarks-developer/src/templates/etaj";

export default (props) => {
  const { pageContext, logoSrc, logoAlt, metaTitle, metaDescription } = props;
  const {
    publicThemeOptions,
    apartamentIndex,
    apartamentPicture,
    apartamente,
    relatedApartments,
    uniqueFloors,
    breadcrumb: { crumbs },
  } = pageContext;
  const apartament = apartamente[apartamentIndex];
  const selectedFloorIndex = uniqueFloors.findIndex((x) =>
    x.label.includes(apartament.floor)
  );
  const selectedFloorIndexNav = uniqueFloors.findIndex((x) => {
    const [_, nr] = x.label.split(" ");
    return nr === etaj.nr;
  });
  const {
    uuid,
    price,
    floor,
    status,
    activ,
    model,
    floorplanposition,
  } = apartament;
  const {
    // uuid: modelNumber,
    rooms,
    size,
    terrace,
    // image,
    description,
  } = model;
  const countDisponibile = apartamente.filter((x) => x.status === "disponibil")
    .length;
  const disponibil = status === APARTMENT_STATUS.DISPONIBIL;
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <title>{`Apartament nr. ${uuid} | ${logoAlt}`}</title>
        {metaTitle && (
          <meta name="title" content={`Apartament nr. ${uuid} | ${logoAlt}`} />
        )}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Helmet>
      <Header logoSrc={logoSrc} logoAlt={logoAlt}></Header>

      <Container
        sx={{
          px: [3, 4],
        }}
      >
        <Row
          sx={{
            pt: ["108px", null, 6],
          }}
        >
          <Col
            sx={{
              flex: ["auto", "auto", 3],
            }}
          >
            <div>
              {/* <Link
                to={
                  `/apartamente-noi/apartament-${next}-camere-${uuid-1}`
                }
                sx={{
                  color: "black",
                  textDecoration: "none",
                  fontSize: 6,
                }}
              >
                <div
                  sx={{
                    width: 0,
                    height: 0,
                    borderTop: "20px solid transparent",
                    borderLeft: " 40px solid #555",
                    borderBottom: "20px solid transparent",
                    mx: "auto",
                  }}
                ></div>
              </Link> */}
              <H1
                sx={{
                  mt: 4,
                  mb: !disponibil ? 2 : 5,
                  marginBottom: 5,
                  textAlign: "center",
                }}
                variant="golden"
              >
               { floor == "S1" ? "Parcare" : "Apartament"} nr. {uuid}
              </H1>
            </div>
            <H4>
              <div
                sx={{
                  a: {
                    display: "inline-block",
                    p: 2,
                    color: "primary",
                  },
                }}
              >
                <Link className="breadcrumb__link" to="/">
                  Home
                </Link>
                /
                <Link
                  className="breadcrumb__link"
                  to={`${publicThemeOptions.apartamenteUrlSegment}/etaj-${floor}`}
                >
                  Etaj {floor}
                </Link>
                /
                <Link
                  to={`/${publicThemeOptions.apartamenteUrlSegment}/etaj-${apartament.floor}`}
                  sx={{
                    variant: "links.default",
                  }}
                >
                  Inapoi la etaj
                </Link>
              </div>
            </H4>
            <Row
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Col
                width={[12 / 12, 12 / 12, 3 / 12]}
                sx={{
                  mb: [5, 0],
                }}
              >
                <div
                  sx={{
                    a: {
                      display: "inline-block",
                      p: 2,
                    },
                  }}
                ></div>
                <div
                  sx={{
                    mt: 2,
                    display: ["none", "none", "block"],
                  }}
                >
                {floor === "S1" ? "" :
                <Collapse title="Apartamente Similare" sx={{ fontSize: 3 }}>
                {!!relatedApartments.length && (
                  <>
                    <H3
                      sx={{
                        mt: 3,
                        px: 2,
                      }}
                    >
                      Alte apartamente cu {rooms} camere
                    </H3>
                    <Grid gap="5" columns={[1]}>
                      {relatedApartments.map((ap) => (
                        <div>
                          <Card>
                            <div
                              sx={{
                                height: ["350px", null, "200px"],
                                overflow: "hidden",
                              }}
                            >
                              <Img fluid={ap.model.image.fluid} />
                            </div>
                            <H4 sx={{ mb: 4 }}>
                              Apartament {ap.uuid}</H4>
                            <Row
                              sx={{
                                display: "flex",
                              }}
                            >
                              <Col>
                                <Row>
                                  <p>
                                    <CustomStrong>
                                      {ap.model.rooms}
                                    </CustomStrong>
                                    <span>camere</span>
                                  </p>
                                  <p sx={{ pl: 5 }}>
                                    <CustomStrong>
                                      {ap.model.size}
                                      <Small>mp</Small>
                                    </CustomStrong>
                                    <span>suprafata utila</span>
                                  </p>
                                </Row>
                              </Col>
                              <Col>
                                <Button
                                  variant="primary"
                                  as={Link}
                                  to={ap.link}
                                >
                                  Vezi detalii
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      ))}
                    </Grid>
                  </>
                )}
              </Collapse>
                }
                </div>
              </Col>
              <Col width={[12 / 12, 12 / 12, 9 / 12]}>
                <Row
                  gap={4}
                  sx={
                    {
                      // pt: ["108px", null],
                    }
                  }
                >
                  <Col
                    sx={{
                      flex: ["auto", "auto", 4],
                    }}
                  >
                    <Row sx={{ position: "relative" }}>
                      <Col width={[12 / 12, 10 / 12]}>
                        <Img fluid={apartamentPicture.fluid} />
                      </Col>
                      <div
                        sx={{
                          display: ["block", "block"],
                          maxWidth: "480px",
                          position: ["flex", "absolute"],
                          mb: [4, 0],
                          mx: ['auto', 0],
                          mt: 0,
                          right: 0,
                          top: 0,
                          width: "260px",
                          height: "260px",
                        }}
                      >
                        <FloorplanMini
                          apartamente={apartamente}
                          selectedApNumber={uuid}
                        />
                      </div>
                    </Row>
                    {floor === "S1" ? "" : 
                                        <Row sx={{ mb: 4, mt: 4, pl: [0, 4] }}>
                                        <Col
                                          sx={{
                                            flex: [null, null, 2],
                                            mr: [null, null, 5],
                                            mb: 4,
                                          }}
                                        >
                                          <H3>Detalii apartament</H3>
                                          <Text
                                            dangerouslySetInnerHTML={{ __html: description }}
                                          />
                  
                                          <br></br>
                                          <div>
                                            <Button>
                                              <a
                                                sx={{
                                                  textDecoration: "none",
                                                  color: "white",
                                                }}
                                                href={`/pdf/${uuid}.pdf`}
                                                download
                                              >
                                                Descarcă Fişă Tehnică
                                              </a>
                                            </Button>
                                          </div>
                                          <div>
                                            <Button>
                                              <a
                                                sx={{
                                                  textDecoration: "none",
                                                  color: "white",
                                                }}
                                                href="/despre"
                                              >
                                                Detalii Tehnice
                                              </a>
                                            </Button>
                                          </div>
                                        </Col>
                                        <Col>
                                          <H3
                                            sx={{
                                              backgroundColor: (theme) =>
                                                theme.floorplan.colors[status],
                                              color: "white",
                                              px: 4,
                                              py: 3,
                                              m: 0,
                                            }}
                                          >
                                            <span
                                              sx={{
                                                opacity: 0.9,
                                              }}
                                            >
                                              {renderStatus(apartament)}
                                            </span>
                                          </H3>
                                          <Card>
                                            <Row
                                              sx={{
                                                display: "flex",
                                              }}
                                            >
                                              <Col>
                                                <Strong
                                                  sx={{
                                                    color: "tertiary",
                                                  }}
                                                >
                                                  {rooms}
                                                </Strong>{" "}
                                                camere
                                              </Col>
                                              <Col>
                                                Etaj
                                                <Strong
                                                  sx={{
                                                    color: "tertiary",
                                                  }}
                                                >
                                                  {apartament.floor}
                                                </Strong>
                                              </Col>
                                            </Row>
                  
                                            <Row
                                              sx={{
                                                display: "flex",
                                                mt: 4,
                                              }}
                                            >
                                              <Col>
                                                <Small>
                                                  Suprafata utila
                                                  <br />
                                                </Small>
                                                <Strong
                                                  sx={{
                                                    color: "tertiary",
                                                  }}
                                                >
                                                  {size}
                                                  <Small>mp</Small>
                                                </Strong>
                                              </Col>
                                              <Col>
                                                <Small>
                                                  Terasa
                                                  <br />
                                                  de la
                                                </Small>
                                                <Strong
                                                  sx={{
                                                    color: "tertiary",
                                                  }}
                                                >
                                                  {`${terrace}`}
                                                  <Small>mp</Small>
                                                </Strong>
                                              </Col>
                                            </Row>
                                          </Card>
                                        </Col>
                                      </Row>
                    }


                    <Row>
                      <Col>
                        {disponibil && (
                          <div sx={{ maxWidth: "700px", pl: [0, 4] }}>
                            <H3 sx={{ mt: 5 }}>Cere mai multe detalii </H3>
                            <Row>
                              <Col>
                                <Text variant="small">
                                  Acest material nu reprezinta o oferta sau un
                                  contract si are scop informativ. Preturile
                                  prezentate sunt orientative, nu reprezinta
                                  oferta de vanzare si pot suferi modificari.
                                  Datorita schimbarilor ce pot interveni pe
                                  durata constructiei, suprafetele, finisajele
                                  si specificatiile tehnice pot suferi
                                  modificari fara o notificare prealabila dar in
                                  deplina concordanta cu orice contract semnat.
                                  Apartamentele se livreaza semifinisate.
                                </Text>
                              </Col>
                            </Row>
                            <div
                              sx={{
                                mt: 3,
                              }}
                            >
                              <MoreDetails
                                apartament={apartament}
                                siteTitle={`sunt de acord sa primesc informari comerciale referitoare la proiectul ${data.site.siteMetadata.title}`}
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          sx={{
            mt: 2,
            display: ["block", "block", "none"],
          }}
        >

          <Collapse title="Apartamente Similare" sx={{ fontSize: 3 }}>
            {!!relatedApartments.length && (
              <>
                <H3
                  sx={{
                    mt: 3,
                    px: 2,
                  }}
                >
                  Alte apartamente cu {rooms} camere
                </H3>
                <Grid gap="5" columns={[1]}>
                  {relatedApartments.map((ap) => (
                    <div>
                      <Card>
                        <div
                          sx={{
                            height: ["350px", null, "200px"],
                            overflow: "hidden",
                          }}
                        >
                          <Img fluid={ap.model.image.fluid} />
                        </div>
                        <H4 sx={{ mb: 4 }}>Apartament {ap.uuid}</H4>
                        <Row
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Col>
                            <Row>
                              <p>
                                <CustomStrong>{ap.model.rooms}</CustomStrong>
                                <span>camere</span>
                              </p>
                              <p sx={{ pl: 5 }}>
                                <CustomStrong>
                                  {ap.model.size}
                                  <Small>mp</Small>
                                </CustomStrong>
                                <span>suprafata utila</span>
                              </p>
                            </Row>
                          </Col>
                          <Col>
                            <Button variant="primary" as={Link} to={ap.link}>
                              Vezi detalii
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  ))}
                </Grid>
              </>
            )}
          </Collapse>
        </div>
      </Container>
      <br />
      <Footer />
    </>
  );
};

const renderStatus = (apartament) => {
  if (apartament.status === APARTMENT_STATUS.DISPONIBIL && apartament.price) {
    return (
      <>
        {apartament.price.toLocaleString()}
        <span sx={{ fontSize: 4 }}></span>
      </>
    );
  }
  return apartament.status;
};

const CustomStrong = (props) => (
  <Strong sx={{ display: "block", lineHeight: "10px" }} {...props} />
);
