/** @jsx jsx */
import { jsx } from 'theme-ui'

import React from 'react'
import Img from 'gatsby-image'

import {
  FunkyTitle,
  Row,
  Col,
  Small,
  Strong,
  Plx,
} from 'gatsby-theme-landmarks-core'

export const CardOverview = props => {
  const { title, subtitle, fluid, size, rooms, terrace } = props
  return (
    <Row>
      <Col sx={{ flex: [0, 0, 3] }}>
        <FunkyTitle
          title={title}
          subtitle={subtitle}
          sx={{
            mb: 3,
            textAlign: 'right'
          }}
        />
        <Plx
          parallaxData={[
            {
              start: 'self',
              duration: 1000,
              properties: [
                {
                  startValue: 0,
                  endValue: -100,
                  property: 'translateY'
                }
              ]
            }
          ]}
        >
          <Img fluid={fluid} />
          {props.children}
        </Plx>
      </Col>
      <Col sx={{ pl: 4, pt: [null, null, '174px'] }}>
        <p>
          <CustomStrong>
            {size}
            <Small>mp</Small>
          </CustomStrong>
          <span>suprafata utila</span>
        </p>
        <p>
          <CustomStrong>{rooms}</CustomStrong>
          <span>camere</span>
        </p>
        <p>
          <CustomStrong>
            {terrace}
            <Small>mp</Small>
          </CustomStrong>
          <span>terasa</span>
        </p>
      </Col>
    </Row>
  )
}

const CustomStrong = props => (
  <Strong sx={{ display: 'block', lineHeight: '10px' }} {...props} />
)